.news__container{
    display: flex;
    margin: 2rem 2rem;
    /* align-items: center; */
    justify-content: center;
    height: 100vh; /* Optionally, set the height of the container */
}
.news__card {
    max-width:50rem;
    /* border: 1px solid #ddd; */
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border: 1px solid #ccc; /* Optional: Add a border for better visualization */
    max-height: 100vh; /* Optional: Set a maximum width for the card */
    overflow: auto; /* Enable scrolling for overflow content */
  }


  
   .news__img {
   /* // object-fit: cover; */
    display: block;
    width: 100%;
    height: 15rem;
    /* object-fit: cover; */
    object-fit: cover;

  }
  
  .news__content {
    padding: 1rem 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  
  .news__title {
    text-align: center;
    font-size: 2rem;
    font-weight: 500;
    color: #0d0d0d;
    margin-bottom: 1.5rem;

  }
  
  .news__description {
    color: #555;
    font-size: 1rem;
    letter-spacing: 0.1rem;
    /* line-height: 1.7; */
    color: #3d3d3d;
    /* margin-bottom: 2.5rem; */

  }




.grid {
  display: grid;
  width: 114rem;
  grid-gap: 6rem;
  grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
  align-items: start;
}
@media (max-width: 60em) {
  .grid {
    grid-gap: 3rem;
  }
}
.grid__item {
  background-color: #fff;
  border-radius: 0.4rem;
  overflow: hidden;
  box-shadow: 0 3rem 6rem rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: 0.2s;
}
.grid__item:hover {
  transform: translateY(-0.5%);
  box-shadow: 0 4rem 8rem rgba(0, 0, 0, 0.2);
}



.news__btn {
  display: block;
  width: 100%;
  padding: 1.5rem;
  font-size: 2rem;
  text-align: center;
  color: #3363ff;
  background-color: #e6ecff;
  border: none;
  border-radius: 0.4rem;
  transition: 0.2s;
  cursor: pointer;
}
.news__btn span {
  margin-left: 1rem;
  transition: 0.2s;
}
.news__btn:hover, .news__btn:active {
  background-color: #dce4ff;
}
.news__btn:hover span, .news__btn:active span {
  margin-left: 1.5rem;
}