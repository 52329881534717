
.header {
    /* margin-top: 1rem; */
    /* width: 44%; */
    /* width: 100%; */
    
    align-items: center;
    justify-content: center;
    padding-top: 5rem;
    height: 15rem;
    overflow: hidden;
    
    /* background: black;    */
    /* background: linear-gradient(90deg,  var(--color-custom-500) 0%, rgb(4, 4, 11, 0.965) 100%);  */
    background: linear-gradient(90deg, rgba(4, 4, 11, 0.965) 100%, var(--color-custom-500) 0%); 
  
    /* opacity: 0.5;   */
    /* background:  var(--color-custom-600);    */
    /* border-bottom: 2rem solid var(--color-custom-400);  */
}
.header__container{
    
    width: 100%;
    height: 100%;
    position: relative;
    display: grid;
    place-items: center;
    /* background: black;   */
}

 .header__container-bg{
   
    position: absolute;
     opacity: 0.3; 
    width: 50%;

} 
.header__container-bg img{
   margin-top: 5rem;
   border-radius: 0.5rem;
    overflow: hidden;
   
     opacity: 0.8; 

}

.header__content{
    position: relative;
    width: 44%;
    margin: 0 auto;
    text-align: center;
    color: var(--color-custom-100);

}
.header__content h2{
   margin-bottom: 1rem;
   padding: 0.2rem;
   color:white;
   /* color: var(--color-custom-100); */
   font-size: 2rem;


}
.header__content p{
    color: var(--color-custom-100);; 
}


.preformatted-text {
  white-space: pre-wrap; /* Or white-space: pre; */
}


 /* ################# MEDIA QUERIES(medium screens  ###################### */

@media screen  and (max-width:1024px){
    .container{
        width:var(--container-width-md);
    }
    h1{
        font-size: 2rem;
    }
    h2{
        font-size: 1.6rem;
    }
    .header__content{
        width: 76%;
    }
}

 /* ################# MEDIA QUERIES(small screens  ###################### */

 @media screen  and (max-width:600px){
    .container{
        width:var(--container-width-md);
    }
    h2{
        font-size: 0.8rem;
        line-height: 2.3;
    }
    section{
        margin-top: 7rem;
    }
    .header{
    height: fit-content;
    }
    
    .header__content{
        width: var(--container-width-md);
        padding:  3rem 0;
    }
    .header__content p{
        font-size: 0.85rem;
    }
}


/* Base Styles */
.header {
  width: 100%;
}

.header__container {
  display: flex; /* Allow horizontal layout initially */
  align-items: center; /* Center content vertically */
}

.header__container-bg img {
  max-width: 100%; /* Ensure image scales down */
}
