
  .values__container{
   display: grid;
   grid-template-columns: 38% 50%;
   gap: 12%;
  }

  .values__image{
   filter: saturate(0.25);
   transition: var(--transition);
   
   /* height: 100px; */
   /* max-height: 100px; Set the maximum width to 100% of the parent container */
  /* height: auto; /* A */

  }

  .values__image img{
    border-radius: 2rem;
    /* height: 100px; */
    max-height: 100vh;
   /* height: auto; /* A */
   transition: filter 0.3s ease-out;
 
   }
  
   .values__image:hover{
   /* filter: saturate(1); */
  filter: grayscale(0%); 
  } 

  /* .values__image:hover img {
    filter: grayscale(0%); /* Set grayscale to 0% to remove it (full color) 
  } */
  .values__right p{
  margin: 1.5rem  0 5rem;

  
   
  }
  .values__wrapper {
   display: grid;
   grid-template-columns: 1fr 1fr;
   gap: 3.4rem 2.4rem;
}

.card.values__value {
 padding-top: 3.5rem;
 text-align: left;
 position: relative;
}
/* 
.card.values__value span{
   
   position: absolute;
   top: -1.5rem
  } */

  

  .values__value img{
    position: absolute;
    top: -1.5rem;
    margin-left: 1.5rem;
    /* margin-bottom: 1rem;*/
    border-radius: 5rem; 
    max-height: 8rem;
    max-width: 8rem;
    transition: transform 0.3s ease-out;
   }



.values__value:hover img {
  transform: scale(1.1); /* Adjust the scale value as needed */
}

   .values__value small{
    text-align: justify;
    margin-top: 1rem;
    padding:0;
   }
  


   /* ################# MEDIA QUERIES(Medium screens  ###################### */

 @media screen  and (max-width:1024px){
   
     
      /* VALUES */
      
      .values__container {
        grid-template-columns: 1fr;
        justify-content: center; 
        gap: 4rem; 
      }
      .values__wrapper {
        gap: 4rem 3rem;
      }

      .values__image{
        /* display: none;  */
      }

      
     
}

 /* ################# MEDIA QUERIES(small screens  ###################### */

 @media screen  and (max-width:600px){
  
    /* VALUES */
    .values__container{
      grid-template-columns: 1fr;
    }
    .values__wrapper{
      grid-template-columns: 1fr;
      gap:2rem;
    }
    .card.values__value {
      width: 84%;
      margin-inline:auto ;
    }

      
 }