  
   footer {
    /* background: var(--color-primary);  */
     /* background: linear-gradient(90deg, rgba(4, 4, 11, 0.965) 0%, rgb(78, 149, 215) 100%);  */
     background: linear-gradient(90deg, rgba(4, 4, 11, 0.965) 0%, var(--color-custom-500) 100%); 
     /* opacity: 0.5;  */
    /* margin-top: 1rem; */
    padding-top: 1rem;
    font-size: 0.9rem;
    color: var(--color-custom-100);

   }

   .footer__container {
    /* padding: 1.5rem 0; */
   /* border-top: 0.1rem solid var(--color-primary-variant); */
   /* padding-top: 5rem; */
    display: grid;
    grid-template-columns: 25rem 1fr 1fr 1fr;
    gap: 6rem;
   }

   .footer__container article {
    display: flex;
   flex-direction: column;
   align-items: flex-start;
   gap: 0.7rem;

   }

   .footer__container article  p{
    margin-top: 0.5rem; 

   }

   .footer__container article h4{
    margin-bottom: 0.6rem;
    font-size: 1.2rem;
    font-weight: bold;
 
    }

  .footer__socials{
      margin-top: 1rem;
      display:flex ;
      gap: 1rem;
      align-items: center;
  }
  .footer__container article a {
    color:var(--color-custom-200);
    margin-top: 0px; /* Adjust the margin-bottom value as needed */
    margin-bottom: 0px; /* Adjust the margin-bottom value as needed */
  }
  .footer__socials a {
       background: var(--color-custom-100);
       padding: 0.7rem;
       border: 1px solid transparent ;
       border-radius: 0.6rem;
       transition: var(--transition);

  }
  .footer__socials svg {
    background: var(--color-custom-600);
  }

  .footer__socials a :hover {
   border-color: var(--color-custom-100);
   background: transparent;
  }

  .footer__copyright {
    /* background: linear-gradient( 90deg, rgb(4, 4, 11, 0.965)  0%, rgba(4, 4, 11, 0.965)100%);  */
    background: linear-gradient(90deg,  var(--color-custom-500) 0%, rgb(4, 4, 11, 0.965) 100%); 
    /* background: linear-gradient(90deg, rgba(4, 4, 11, 0.965) 0%, var(--color-custom-500) 100%);  */
     opacity: 0.5;  
   color: var(--color-custom-100);
   text-align: center;
   padding: 1.5rem 0;
   /* border-top: 0.1rem solid var(--color-primary-variant); */
   /* margin-top: 1rem; */

   }

     .footer__container article img {
      
        width: 5rem;
        height: 5rem;
        display: block; /* Ensures the image behaves as a block element */
        margin: 0 auto; /* Centers the image horizontally */
        border-radius:2rem;
        transition: transform 0.3s ease-out;
       } 



.footer__container article img:hover {
  transform: scale(1.1); /*Adjust the scale value as needed */
  /* filter: grayscale(0%);  */
  /* filter: saturate(1); */
}





   /* ################# MEDIA QUERIES(Medium screens  ###################### */

   @media screen and (max-width: 1024px) {
    /* FOOTER */
    .footer__container {
      grid-template-columns: 1fr 1fr; /* Adjust the number of columns as needed */
      gap: 3rem;
    }
  
    .footer__container article {
      align-items: flex-start; /* Adjust alignment as needed */
    }
  
    .footer__address,
    .footer__telephone {
      text-align: left; /* Adjust text alignment as needed */
    }
  }


/* ################# MEDIA QUERIES(small screens) ###################### */

@media screen and (max-width: 600px) {
  /* FOOTER */
  .footer {
    margin-top: 1rem;
    text-align: center;
  }

  .footer__container {
    grid-template-columns: 1fr;
    gap: 3rem;
    margin: 0;
    text-align: center;
  }

  .footer__container article {
    align-items: center;
  }

  .footer__container article p {
    align-items: center;
  }

  .footer__address,
  .footer__telephone {
    text-align: center;
  }

  .footer__link {
    margin-top: 2rem;
  }

  .logo {
    margin-top: 10px;
  }

  .footer__container article img {
    width: 5rem;
    height: 5rem;
    display: block;
    margin: 0 auto;
    border-radius: 2rem;
    transition: transform 0.3s ease-out;
  }
  .footer__telephone {
    text-align: center; /* Center the text */
  }
  
  .footer__container article p {
    display: flex; /* Use flexbox */
    align-items: center; /* Center vertically */
    justify-content: center; /* Center horizontally */
  }
  
}
