.projects__container{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 4rem;
    padding-bottom: 1rem;

}
.card.project{
    padding: 1rem;
}
p {
    font-size: 0.9rem;
}

.project__img{
    height: 12rem; /* Adjust the height as needed */
    width: auto; /* Maintain aspect ratio */
    border-radius: 0 2rem;
    overflow: hidden;
    transition: transform 0.3s ease-out;
}

 
.project__img:hover {
    transform: scale(1.1); /* Adjust the scale value as needed */
  }


.project h2{
    text-align:center;
    margin-top: 2rem;
    /* background: #34495E; */ 
    background: white;
    /* padding: 0.2rem; */
     border-radius: 0.5rem; 
    color: var(--color-custom-500);
    font-size: 1rem;
    line-height: 1.2rem;
    font-weight: bold;
}

.project p{
    margin-top: 0.5rem;
    text-align: justify;
}

.project__socials{
    display: flex;
    gap: 1rem;
    justify-content: center;
    align-items: center;
    margin: 2rem 0;
}


.project:hover a{
    
    background: var(--color-custom-500);
  

}

/* ################# MEDIA QUERIES(medium screens  ###################### */

@media screen  and (max-width:1024px){
    .projects__container{
        grid-template-columns: 1fr 1fr;
        gap: 2.5rem;
    }
}

 /* ################# MEDIA QUERIES(small screens  ###################### */

 @media screen  and (max-width:600px){
    .projects__container{
        grid-template-columns: 1fr;
        gap: 2rem;
    }
    .card.project{
        width: 90%;
        margin-inline: auto;
    }
}