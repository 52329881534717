.galleryWrap {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  margin: 0 auto;
}


  
  .galleryWrap .single {
    /* Existing styles */
    margin-top: 4px;
    margin-bottom: 4px;
    width: calc(6% - 10px); /* Adjust based on desired number of columns (here, 4) */
    height: 60px;  /* Adjust height as needed */
    cursor: pointer;
  }

  .galleryWrap .single img {
    padding-top: 10px;
    width: 100%; /* Maintain aspect ratio */
    height: 100%; /* Fill the entire div */
    border-radius: 10px;
    object-fit: cover; /* Crop image to fit while maintaining aspect ratio */
  }

.galleryWrap .single img:hover {
  transform: scale(1.02);
}

.sliderWrap {
    /* Existing styles */
    top: 0;  /* Removed position: fixed */
    bottom: 0;
    left: 0;
    right: 0;
    /* margin-top: 4px; */
    margin-bottom: 4px;
    z-index: 1000;  /* Increased z-index for overlay */
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
  

.sliderWrap .btnClose, .sliderWrap .btnPrev, .sliderWrap .btnNext {
  position: fixed;
  cursor: pointer;
  opacity: 0.6;
  color: #fff;
  z-index: 9999;
} 

.btnNext:hover, .btnPrev:hover, .btnClose:hover {
  opacity: 1;
}

.sliderWrap .btnClose {
  top: 40px;
  right: 40px;
}

.sliderWrap .btnPrev {
  top: 50%;
  transform: translateY(-50%);
  left: 40px;
}

.sliderWrap .btnNext {
  top: 50%;
  transform: translateY(-50%);
  right: 40px;
}

.fullScreenImage {
  width: calc(50% - 40px);
  height: calc(50% - 40px);
  display: flex;
  align-items: center;
  justify-content: center;

}


.fullScreenImage img {
  max-width: 100%;
  height: 450px; /* Set fixed height */
  object-fit: cover; /* Crop image to fit while maintaining aspect ratio */
  /* max-height: 100%; */
  pointer-events: none;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */  
  border-radius: 12px;
}

.photogallery-container {
    display: flex; 
    justify-content: center;
    align-items: center;
    min-height: 100vh; /* Set minimum height to cover viewport */
    background-color: #f0f0f0; /* Optional background color */
  }
  
  .loading-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* semi-transparent background */
    display: flex;
    justify-content: center; /* center horizontally */
    align-items: center; /* center vertically */
    z-index: 1000; /* ensure it's above other content */
  }
  
  .loading-container p {
    color: white;
    font-size: 20px;
  }

  .caption {
    position: absolute;
    bottom: 10px; /* Adjust the distance from the bottom as needed */
    left: 0;
    right: 0;
    /* background: rgba(0, 0, 0, 0.5); */
    color: white;
    padding: 10px; /* Adjust the padding as needed */
    font-size: 24px; /* Increase the font size as needed */
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Add a shadow to the text */
  }
  
  
  