.react-player-container {
    width: 100%;
    height: 100vh; /* Set the height to 100% of the viewport height */
    position: relative;
    background: black;
    /* background: var(--color-primary);   */
    /* position: relative; */
    /* width: 100%; */
    /* height: 0; */
    margin-right: 4rem ;
    /* margin-left: 4rem ; */
     padding: 2rem; 
  }
  
  .react-player-container .react-player {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; 
  }