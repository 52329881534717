/* src/index.css or your main stylesheet */
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;


  /* new */
  font-family: 'Montserrat', sans-serif;
  /* color: var(--color-gray-200); */
  /* color:var(--color-custom-200); */
  color:black;
  line-height: 1.7;
  overflow-x: hidden;
      /* background: var(--color-custom-blue);    */
     /* background: linear-gradient(90deg, rgba(239, 239, 239, 0.965) 0%, rgb(162, 163, 163) 100%); */
     /* background: linear-gradient(180deg, rgba(74, 74, 239, 0.965) 0%, rgb(206, 218, 239) 100%); */
     background:#fff;
    /* background: var(--color-bg-custom) url('./images/bg_texture.png');   */
     /* background: var(--color-bg-custom) url('./components/images/img-3.jpg');  */
    margin-inline: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*,*::before,*::after{
  margin: 0px;
  padding:0px;
  outline: 0px;
  border:0px;
  list-style: none;
  text-decoration: none;
  box-sizing: border-box;
}
:root{
  --primary-hue:210;
  --gray-hue:240;
  /* --color-primary:hls(var(--primary-hue),100%,50%); */
  --color-primary: rgb(78, 149, 215); 
  --color-primary-variant: rgb(169, 199, 221); 
  /* --color-primary-variant:hls(var(--primary-hue),100%,65%); */
  --color-secondary:#ffb116;
  --color-bg-custom:#200e42c4;
  --color-custom-100:#fefeffe7;
  --color-custom-200:#d4d4d6c4;
  --color-custom-400:#68686ac4;
  --color-custom-500:#282848c4;
  --color-custom-600:#4e4e50c4;
   /* --color-custom-blue:#6b6b6d;  */
   --color-custom-blue:#3e3a44; 
    /* --color-custom-blue:#617178; */
 
  background: linear-gradient(90deg, rgba(4, 4, 11, 0.965) 0%, rgb(17, 76, 177) 100%);
   
  /* --color-custom-blue:#363737 ; */
 
  
  --color-gray-100:hls(var(--gray-hue),47%,94%);
  --color-gray-200:hls(var(--gray-hue),26%,75%);
  --color-gray-300:hls(var(--gray-hue),24%,64%);
  --color-gray-400:hls(var(--gray-hue),16%,41%);
  --color-gray-500:hls(var(--gray-hue),44%,25%);
  --color-gray-600:hls(var(--gray-hue),48%,15%);

  --container-width-lg:80%;
  --container-width-md:90%;
  --transition:all 500ms ease;
}
/* GENERAL STYLES */

.container{
  width: var(--container-width-lg);
  max-width: 1920px;
  /* margin: 0 auto' */
  margin-inline: auto;
}
h1,h2,h3,h4,h5{
  line-height: 1.2rem;
  /* color: var(--color-custom-100); */
  /* color: var(--color-primary); */
  /* text-transform: uppercase; */
  font-weight: bold;

}
h1{
   font-size: 60px; 
  line-height: 3rem;
}
h2{
  line-height: 2rem;
}
a{
  /* color: var(--color-gray-100); */
  color:var(--color-custom-100);

}
img{
  display:block;
  object-fit: cover;
  width: 100%;
}



.btn:hover {
  
  background: var(--color-secondary);
  color:var(--color-primary-variant);
}

.btn .lg{
padding: 1.2 rem;
border-radius: 3rem;
font-size: 1.1rem;
}

.btn .sm{
  /* padding: 0.4rem 1.2rem; */
  font-size: 0.9rem;
  background: red;
}
.btn .sm :hover{
  background: var(--color-custom-100);
}

section{
  margin-top: 3rem;
}



/* ################# MEDIA QUERIES(medium screens  ###################### */

@media screen  and (max-width:1024px){
  .container{
      width:var(--container-width-md);
  }
  h1{
      font-size: 2rem;
  }
  h2{
      font-size: 1.6rem;
  }
  .header__content{
      width: 76%;
  }
}

/* ################# MEDIA QUERIES(small screens  ###################### */

@media screen  and (max-width:600px){
  .container{
      width:var(--container-width-md);
  }
  h1{
      font-size: 2.2rem;
      line-height: 1.3;
  }
  section{
      margin-top: 7rem;
  }
  .header{
  height: fit-content;
  }
  
  .header__content{
      width: var(--container-width-md);
      padding:  3rem 0;
  }
  .header__content p{
      font-size: 0.85rem;
  }
}
