/* .about__section-image{
    border-radius: 50%;
    overflow: hidden;
    transform: skew(15deg);
    transition: var(--transition);

} */

.about__section-image {
    width: 300px; /* Set a fixed width */
    height: 300px; /* Set a fixed height */
    border-radius: 50%;
    overflow: hidden;
    transform: skew(15deg);
    transition: var(--transition);
  }
  
  .about__section-image img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Maintain aspect ratio and cover the container */
  }

.about__section-image:hover {
    box-shadow: 0 0 10px rgba(83, 210, 252, 0.5); /* Add a shadow */
    border: 2px solid #29b9e9; /* Add a border */
    transform: skew(0);
   
      
    
   
      
  }

.about__section-image:nth-child(2){
    transform: skew(-15deg);
}

.about__section-image:nth-child(2):hover{
    transform: skew(0);
    outline-color: #9e64f4;
}

.about__section-content h2{
   
    background: #34495E;
    padding: 0.1rem;
     border-radius: 0.5rem; 
    color: var(--color-custom-100);
    font-size: 1.5rem;

}




.about__section-content p{
    margin-bottom: 1rem;

}

.about__story-container, .about__mission-container{
    display: grid;
    grid-template-columns: 40% 50%;
    gap: 0%;
}

.about__vision-container{
    display: grid;
    grid-template-columns: 50% 40%;
    gap: 0%;
}

/* ################# MEDIA QUERIES(medium screens  ###################### */
@media screen  and (max-width:1024px){
    .about__section-image{
        width: 60%;
    }
    .about__section-content h1{
        margin-bottom: 1.2rem;
    }
    .about__story-container, .about__vision-container, .about__mission-container{
        grid-template-columns: 1fr;
        gap: 3rem;
    }
    .about__vision .about__section-image{
        grid-row: 1;
    }
}

/* ################# MEDIA QUERIES(small screens  ###################### */
@media screen  and (max-width:600px){
   .about__section-image{
    width: 80%;
    margin-inline:auto ;
   }
   .about__story-container, .about__vision-container, .about__mission-container{
    gap: 2rem;
}
}