
.section__head{
    display: flex;
    align-items: center;
    gap: 1rem;
  
   }
   .section__head h2{
    background: #34495E;
    padding: 0.2rem;
     border-radius: 0.5rem; 
    color: var(--color-custom-100);
    font-size: 1.5rem;
  
  }
   .section__head span{
     background: var(--color-custom-500);
     padding: 0.2rem;
     border-radius: 1rem;
     color: var(--color-secondary);
     font-size: 1.5rem;
   
   }
   