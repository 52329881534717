/* gallery.css */

.react-image-gallery-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Set the desired height of the container, adjust as needed */
    background: black;
  }
  
  .custom-image-gallery img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain; /* Maintain aspect ratio and fit the image within the container */
  }
  