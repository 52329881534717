/* herosection.css */
video {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -1;
}

.hero__container {
  padding: 5rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.5);
}

.hero__container > h1 {
  color: hsl(0, 0%, 100%);
  font-size: 3rem;
  text-align: center;
}

.hero__container > p {
  margin-top: 8px;
  color: #fff;
  font-size: 4rem; 
  text-align: center;
}

.hero-btns {
  margin-top: 32px;
}

.btns {
  margin: 6px;
  padding: 10px 20px;
  background-color: #007bff; /* Primary button color */
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.2rem;
}

.btns:hover {
  background-color: #0056b3; /* Darker shade on hover */
}

@media screen and (max-width: 960px) {
  .hero__container > h1 {
      font-size: 70px;
  }
}

@media screen and (max-width: 768px) {
  .hero__container > h1 {
      font-size: 50px;
  }
} 
