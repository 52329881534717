.login__container{
     /* display: grid;  */
    /* place-items: center; */
    /* background: white; */
}
.login__wrapper{
    /* width: fit-content; */
    /* display:flex; */
    /* gap:2rem; */
   
}
.login__wrapper a{
    background: var(--color-primary);
    font-size: 2rem;
    border: 2px solid transparent;
    transition: var(--transaction);
    padding: 2rem;
    border-radius: 1.5rem;
}

.login__wrapper a:hover{
    background: transparent;
    border-color: var(--color-custom-100);
}

.link-reset {
    color: inherit;
    text-decoration: underline; /* or any other default text decoration */
  }
  
  .link-reset:hover {
    color: inherit; /* Reset hover color to the default */
    text-decoration: underline; /* Reset hover text decoration to the default */
  }

/* ################# MEDIA QUERIES(small screens  ###################### */

@media screen  and (max-width:600px){
 .login__wrapper{
    gap: 1.5rem;
 }

 .login__wrapper a{
     padding: 1rem; 
     font-size: 1.2rem;
     border-radius: 1rem;

 }
   
}