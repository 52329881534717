.contact{
    padding-bottom: 3rem;
}
.contact__container{
    display: grid;
    place-items: center;
    padding-bottom: 2rem;
}
.contact__wrapper{
    width: fit-content;
    display:flex;
    align-items: center;
    justify-content: center; /* Center the icons horizontally */
    gap:2rem;
}
.contact__wrapper a{
    background: var(--color-custom-500);
    font-size: 2rem;
    border: 2px solid transparent;
    transition: var(--transaction);
    padding: 2rem;
    border-radius: 1.5rem;
}

.contact__wrapper a:hover{
    background: transparent;
    border-color: var(--color-primary);
}

/* ################# MEDIA QUERIES(small screens  ###################### */

@media screen  and (max-width:600px){
 .contact__wrapper{
    gap: 1.5rem;
 }

 .contact__wrapper a{
     padding: 1rem; 
     font-size: 1.2rem;
     border-radius: 1rem;

 }
   
}