.navbar {
    /* background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%); */
    /* background: linear-gradient(90deg, rgba(4, 4, 11, 0.965) 0%, rgb(17, 76, 177) 100%); */
    /* background: linear-gradient(90deg, rgba(4, 4, 11, 0.965) 0%, rgb(78, 149, 215) 100%);  */
     background: linear-gradient(90deg, rgba(4, 4, 11, 0.965) 0%, var(--color-custom-500) 100%); 
   
    /* opacity: 0.5;  */
    color: var(--color-custom-100);
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: sticky;
    top: 0;
    z-index: 999;
  }
  
  .navbar-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    max-width: 1500px;
  }
  
  .navbar-logo {
    color: #fff;
    justify-self: start;
    margin-left: 20px;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;
  }

  .logo-image {
    width: 50px;
    height: auto;
    margin-left:  0.5rem;
    transition: transform 0.3s ease-out;
   
  }

  .logo-image:hover {
    transform: scale(1.1); /*Adjust the scale value as needed */
  }

  
  .fa-typo3 {
    margin-left: 0.5rem;
    font-size: 1.8rem;
  }

  
  .nav-menu {
    /* Other styles */
    display: flex;
    align-items: center; /* Align items vertically */
    /* list-style: none; */
    /* margin-right: 2rem; Adjust margin as needed */
    /* display: grid; */
    grid-template-columns: repeat(4, auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    width: 60vw;
    justify-content: end;
    margin-right: 2rem; 
  }
  
  .nav-item {
    height: 80px;
  }
  
  .nav-links {
    color: #fff;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
  }
  
  .nav-links:hover {
    border-bottom: 4px solid #fff;
    transition: all 0.2s ease-out;
  }
  
  .fa-bars {
    color: #fff;
  }
  
  .nav-links-mobile {
    display: none;
  }
  
  .menu-icon {
    display: none;
  }
  
  @media screen and (max-width: 960px) {
    .NavbarItems {
      position: relative;
    }
  
    .nav-menu {
      top: 60px; /* Adjust the top position */
        height: calc(100vh - 60px); /* Adjust the height of the menu */
        padding-top: 20px; /* Add padding to the top of the menu */

      display: flex;
      flex-direction: column;
      width: 100%;
      /* height: 90vh; */
      position: absolute;
      /* top: 80px; */
      left: -100%;
      opacity: 1;
      transition: all 0.5s ease;
    }

    .nav-item {
      height: auto; /* Set height to auto or a smaller value */
  }
  
    .nav-menu.active {
      background: #242222;
      left: 0;
      opacity: 1;
      transition: all 0.5s ease;
      z-index: 1;
    }
  
    .nav-links {
      text-align: center;
      padding: 2rem;
      width: 100%;
      display: table;
    }
  
    .nav-links:hover {
      background-color: #fff;
      color: #242424;
      border-radius: 0;
    }
  
    .navbar-logo {
      position: absolute;
      top: 0;
      left: 0;
      transform: translate(25%, 50%);
    }
  
    .menu-icon {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(-100%, 60%);
      font-size: 1.8rem;
      cursor: pointer;
    }
  
    .fa-times {
      color: #fff;
      font-size: 2rem;
    }
  
    .nav-links-mobile {
      display: block;
      text-align: center;
      margin: 2rem auto;
      border-radius: 4px;
      width: 80%;
      text-decoration: none;
      font-size: 1.5rem;
      background-color: transparent;
      color: #fff;
      padding: 14px 20px;
      border: 1px solid #fff;
      transition: all 0.3s ease-out;
    }
  
    .nav-links-mobile:hover {
      background: #fff;
      color: #242424;
      transition: 250ms;
    }
  }
  