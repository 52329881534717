.projects{
    margin: 2rem auto ; 
    /* margin-bottom: 1rem; */
}
.projects__container{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 4rem;

}
.card.project{
    padding: 1rem;
}


.project__img{
    border-radius: 0 2rem;
    overflow: hidden;
}
.project h3{
  
    margin-top: 2rem;
    
}

.projects__container h4{
    text-align: center;
}

.project p{
    margin-top: 0.5rem;
}

.project__socials{
    display: flex;
    gap: 1rem;
    justify-content: center;
    align-items: center;
    margin: 2rem 0;
}

.project__socials a{
    font-size: 0.7rem;
    background: var(--color-custom-600);
    padding: 0.8rem;
    border-radius: 0.7rem;
    transition: var(--transition);

}

.project:hover a{
    
    background: var(--color-custom-500);
  

}

/* ################# MEDIA QUERIES(medium screens  ###################### */

@media screen  and (max-width:1024px){
    .projects__container{
        grid-template-columns: 1fr 1fr;
        gap: 2.5rem;
       
    }
}

 /* ################# MEDIA QUERIES(small screens  ###################### */

 @media screen  and (max-width:600px){
    .projects__container{
        grid-template-columns: 1fr;
        gap: 2rem;
    }
    .card.project{
        width: 90%;
        margin-inline: auto;
    }
}